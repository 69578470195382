import React, { Fragment } from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/Seo'
import ResultScreen from '../components/UI/ResultScreen/ResultScreen'

const FailureScreen = ({ data }) => {
  const {
    seo: {
      frontmatter: {
        seo: seoInfo
      }
    },
    failure: {
      frontmatter: {
        image,
        header,
        buttonText
      },
      html
    }
  } = data

  return (
    <Fragment>
      <SEO data={ seoInfo } />
      <section className="contact-us-page">
        <div className="cu-top-section"></div>
        <div className="cu-page-wrapper result-screen">
          <ResultScreen
            title={header}
            icon={image && image.childImageSharp.fixed}
            description={html}
            buttonName={buttonText}
          />
        </div>
      </section>
    </Fragment>
  )
}

export default FailureScreen

export const contactUsQuery = graphql`
  query {
    seo: markdownRemark(
      fileAbsolutePath: {regex: "/content/additional-content/contact-us/seo-contact-us.md/"}
    ) {
      frontmatter {
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
    }
    failure: markdownRemark (
      fileAbsolutePath: {regex: "/content/additional-content/contact-us/failure-screen.md/"}
    ) {
      frontmatter {
        image {
          childImageSharp {
            fixed (quality: 100, base64Width: 100){
              ...GatsbyImageSharpFixed
            }
          }
        }
        header
        buttonText
      }
      html
    }
  }
`
